import { useState } from "react";
import "./BibleSearch.css";
import loading_gif from "../../assets/images/loading.gif";
import CopyAllButton from "../CopyAllButton/CopyAllButton";
import ClearAllButton from "../ClearAllButton/ClearAllButton";

export default function BibleSearch() {
  const [inputValue, setInputValue] = useState("");
  const [verses, setVerses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [copyNotification, setCopyNotification] = useState("");
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setNoResultsFound(false);
    try {
      const response = await fetch(
        `https://api.suoinuocsong.info/verses?input=${inputValue}`,
        // `http://localhost:5000/verses?input=${inputValue}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) {
        if (response.status === 500) {
          throw new Error("Internal Server Error");
        } else if (response.status === 401) {
          throw new Error("Unauthorized");
        } else {
          throw new Error("Network response was not ok");
        }
      }
      const data = await response.json();
      setVerses(data.result);
      setNoResultsFound(data.result.length === 0);
      if (data.count > 150) {
        setErrorMessage(
          "Tìm kiếm nhiều hơn 150 câu Kinh Thánh nên chỉ hiển thị 150 câu đầu tiên!"
        );
      } else {
        setErrorMessage("");
      }
    } catch (error) {
      if (error.message === "Internal Server Error") {
        setErrorMessage("Địa chỉ Kinh Thánh chưa đúng, xin kiểm tra lại!");
      } else if (error.message === "Unauthorized") {
        setErrorMessage(
          "Hết phiên đăng nhập, vui lòng đăng xuất và đăng nhập lại!"
        );
      } else {
        setErrorMessage(
          "Đã xảy ra lỗi mạng, vui lòng thử lại sau hoặc báo lỗi với Admin!"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const copyAllText = () => {
    const copyContainer = document.createElement("div");

    const versesWithHTML = verses.map((verse) => {
      const verseElement = document.createElement("p");
      verseElement.innerHTML = `<strong>${verse.book} ${verse.chapter}:${verse.verse}</strong> ${verse.text}`;
      return verseElement;
    });

    versesWithHTML.forEach((verseElement) => {
      copyContainer.appendChild(verseElement);
    });

    document.body.appendChild(copyContainer);

    const range = document.createRange();
    range.selectNode(copyContainer);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    document.execCommand("copy");

    window.getSelection().removeAllRanges();
    document.body.removeChild(copyContainer);

    // Show copy notification
    setCopyNotification("Các câu Kinh Thánh đã được copy vào bộ nhớ tạm");
    setShowCopyNotification(true);
    setTimeout(() => {
      setCopyNotification("");
      setShowCopyNotification(false);
    }, 2000);
  };

  const clearAllText = () => {
    setInputValue(""); // Clear the input value
  };

  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
        KINH THÁNH
      </h1>
      <form onSubmit={handleFormSubmit} className="form-wrapper">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Nhập một hoặc nhiều câu Kinh Thánh..."
          className="input-field"
        />
        <button type="submit" className="submit-button">
          Tìm kiếm
        </button>
      </form>
      {isLoading && (
        <div className="loading-wrapper">
          <img src={loading_gif} alt="Loading..." />
        </div>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {!isLoading && verses.length > 0 && (
        <div>
          <div><ClearAllButton onClick={clearAllText}></ClearAllButton></div>
          <div><CopyAllButton onClick={copyAllText} /></div>
          <ul className="result-list">
            {verses.map((verse) => (
              <li
                key={verse.book + verse.chapter + verse.verse}
                className="result-item"
              >
                <strong>
                  {["Giu.", "Plm.", "2 Gi.", "3 Gi.", "Áp."].includes(
                    verse.book
                  )
                    ? `${verse.book} `
                    : `${verse.book} ${verse.chapter}:`}
                  {verse.verse}{" "}
                </strong>
                <span dangerouslySetInnerHTML={{ __html: verse.text }}></span>
              </li>
            ))}
          </ul>
          <div className="result-wrapper">
            <p className="copyright">
              &copy; 2018-2023 Living Stream Ministry [Suối nước sống] - Cựu Ước
              bản Truyền thống và Tân Ước bản Khôi Phục (Chỉ bản văn)
            </p>
          </div>
        </div>
      )}
      {!isLoading && noResultsFound && (
        <div className="no-results-message">
          Không tìm thấy, vui lòng kiểm tra lại.
        </div>
      )}
      {showCopyNotification && (
        <div className="copy-notification">{copyNotification}</div>
      )}
    </div>
  );
}
