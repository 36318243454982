import { useEffect, useState } from "react";
import BibleSearch from "./components/BibleSearch/BibleSearch";
import Login from "./components/Login/Login";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);   

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    // Clear the token from local storage
    localStorage.removeItem("token");
  };

  return (
    <div>
      {!isLoggedIn && <Login onLogin={handleLogin} />}
      {isLoggedIn && (
        <div>
          <div style={{ position: "absolute", top: 33, right: 5 }}>
            <button onClick={handleLogout} style={{ backgroundColor: "#ddd", color: "#c70f15", border: "none", borderRadius: "5px", padding: "10px", "font-size": "16px" }}>Đăng xuất</button>
          </div>
          <BibleSearch />
        </div>
      )}
    </div>
  );
  
}

export default App;