import { useState } from "react";

export default function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    try {
      const response = await fetch(
        "https://api.suoinuocsong.info/login", 
        // "http://localhost:5000/login", 
        {
        credentials: 'include',
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // Store the token in session storage
      localStorage.setItem("token", data.token);
      onLogin();
    } catch (error) {
      setErrorMessage("Email hoặc mật khẩu chưa đúng, xin kiểm tra lại!");
    }
  };

  return (
    <div style={{ maxWidth: "500px", margin: "0 auto", textAlign: "center" }}>
      <h1 style={{ marginBottom: "30px" }}>Đăng nhập</h1>
      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
        <input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Email"
          style={{ marginBottom: "10px", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
        />
        <input
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Mật khẩu"
          style={{ marginBottom: "10px", padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
        />
        <button type="submit" style={{ padding: "10px", backgroundColor: "#ddd", color: "#c70f15", borderRadius: "5px", border: "none" }}>
          Đăng nhập
        </button>
      </form>
      {errorMessage && <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>}
    </div>
  );
}
