import React from "react";
import "./ClearAllButton.css";

export default function ClearAllButton({ onClick }) {
  return (
    <button onClick={onClick} className="clear-all-button">
      Xoá
    </button>
  );
}
